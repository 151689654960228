import Public from '@/components/public/Public.COM';
//import Home from '@/components/public/Home.COM';
//import Register from '@/components/public/registration/Register.COM';
//import AgencyRegistration from '@/components/public/registration/AgencyRegistration.COM';
//import About from '@/components/public/About.COM';
//import Contact from '@/components/public/Contact.COM';
//import Groups from '@/components/public/Groups.COM';
import Login from '@/components/login';
import RecoverPassword from '@/components/public/RecoverPassword';
import ResetPassword from '@/components/public/ResetPassword';
//import ThankyouRegister from '@/components/public/registration/ThankYouRegister';
//import ThankyouAgencyRegistration from '@/components/public/registration/ThankYouAgencyRegistration';
import VerifiedLogin from '@/components/public/VerifiedLogin';
//import ThankyouContact from '@/components/public/ThankYouContact';
//import PrivacyPolicy from '@/components/public/PrivacyPolicy';
//import TermsConditions from '@/components/public/TermsConditions';
//import WebsiteTerms from '@/components/public/WebsiteTerms';
//import Sustainability from '@/components/public/SustainabilityPolicy';
//import Brochures from '@/components/public/Brochures';

//import Destinations from '@/components/public/destinations/Destinations';
//import NewZealandDestinations from '@/components/public/destinations/NewZealand';
//import AustraliaDestinations from '@/components/public/destinations/Australia';

//import DestinationAuckland from '@/components/public/destinations/north-island/DestinationAuckland';
//import DestinationWellington from '@/components/public/destinations/north-island/DestinationWellington';
//import DestinationHawkesBay from '@/components/public/destinations/north-island/DestinationHawkesBay';
//import DestinationTairawhitiGisborne from '@/components/public/destinations/north-island/DestinationTairawhitiGisborne';
//import DestinationTaranaki from '@/components/public/destinations/north-island/DestinationTaranaki';
//import DestinationManawatu from '@/components/public/destinations/north-island/DestinationManawatu';
//import DestinationWairarapa from '@/components/public/destinations/north-island/DestinationWairarapa';
//import DestinationWhanganui from '@/components/public/destinations/north-island/DestinationWhanganui';
//import DestinationBayOfPlenty from '@/components/public/destinations/north-island/DestinationBayOfPlenty';
//import DestinationCoromandel from '@/components/public/destinations/north-island/DestinationCoromandel';
//import DestinationNorthland from '@/components/public/destinations/north-island/DestinationNorthland';
//import DestinationRotorua from '@/components/public/destinations/north-island/DestinationRotorua';
//import DestinationRuapehu from '@/components/public/destinations/north-island/DestinationRuapehu';
//import DestinationTaupo from '@/components/public/destinations/north-island/DestinationTaupo';
//import DestinationWaikato from '@/components/public/destinations/north-island/DestinationWaikato';

//import DestinationCentralOtago from '@/components/public/destinations/south-island/DestinationCentralOtago';
//import DestinationQueenstown from '@/components/public/destinations/south-island/DestinationQueenstown';
//import DestinationDunedin from '@/components/public/destinations/south-island/DestinationDunedin';
//import DestinationChristchurchCanterbury from '@/components/public/destinations/south-island/DestinationChristchurchCanterbury';
//import DestinationFiordland from '@/components/public/destinations/south-island/DestinationFiordland';
//import DestinationNelsonTasman from '@/components/public/destinations/south-island/DestinationNelsonTasman';
//import DestinationSouthland from '@/components/public/destinations/south-island/DestinationSouthland';
//import DestinationWanaka from '@/components/public/destinations/south-island/DestinationWanaka';
//import DestinationMarlborough from '@/components/public/destinations/south-island/DestinationMarlborough';
//import DestinationWestCoast from '@/components/public/destinations/south-island/DestinationWestCoast';
//import DestinationKaikoura from '@/components/public/destinations/south-island/DestinationKaikoura';
//import DestinationHurunui from '@/components/public/destinations/south-island/DestinationHurunui';
//import DestinationClutha from '@/components/public/destinations/south-island/DestinationClutha';
//import DestinationTimaru from '@/components/public/destinations/south-island/DestinationTimaru';

//import DestinationACT from '@/components/public/destinations/australia/DestinationACT';
//import DestinationNSW from '@/components/public/destinations/australia/DestinationNSW';
//import DestinationNT from '@/components/public/destinations/australia/DestinationNT';
//import DestinationQLD from '@/components/public/destinations/australia/DestinationQLD';
//import DestinationSA from '@/components/public/destinations/australia/DestinationSA';
//import DestinationTAS from '@/components/public/destinations/australia/DestinationTAS';
//import DestinationVIC from '@/components/public/destinations/australia/DestinationVIC';
//import DestinationWA from '@/components/public/destinations/australia/DestinationWA';

export const publicRoutes = [
  {
    path: '/',
    component: Public,
    children: [
      /*
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: 'ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      {
        path: 'register/',
        name: 'register',
        component: Register,
      },
      {
        path: 'new-agency-registration/',
        name: 'agencyregistration',
        component: AgencyRegistration,
      },
      {
        path: 'agent-account-requested/',
        name: 'thankyouRegister',
        component: ThankyouRegister,
      },
      {
        path: 'agency-registration-requested/',
        name: 'thankyouAgencyRegistration',
        component: ThankyouAgencyRegistration,
      },
      {
        path: 'terms-and-conditions/',
        name: 'termsConditions',
        component: TermsConditions,
      },
      {
        path: 'website-terms/',
        name: 'websiteTerms',
        component: WebsiteTerms,
      },
      {
        path: 'enquiry-received/',
        name: 'thankyouContact',
        component: ThankyouContact,
      },
      {
        path: 'privacy-policy/',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
      },
      {
        path: 'sustainability-policy/',
        name: 'sustainability',
        component: Sustainability,
      },
      */
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          title: 'ANZCRO Connect | Log in',
          description:
            'ANZCRO Connect - The online self-service booking platform that makes booking your next Australian, New Zealand or South Pacific itinerary a snap.',
        },
      },
      {
        path: 'verified-login/',
        name: 'verifiedLogin',
        component: VerifiedLogin,
      },
      {
        path: 'recover-password/',
        name: 'recoverPassword',
        component: RecoverPassword,
      },
      {
        path: 'reset-password/',
        name: 'resetPassword',
        component: ResetPassword,
      },
    ],
  },
];
